<template>
  <BasePage :loading="postsGetter.loading">
    <template v-if="postsGetter.posts.length && !postsGetter.loading">
      <Articles :posts="postsGetter.posts" :type="type" />
      <!-- :meta="meta" getPosts="getPosts"  -->
    </template>
  </BasePage>
</template>

<script>
// import ApiService from '@/services/api.service';
import { mapActions, mapGetters } from 'vuex';
// import PostItem from '@/components/PostItem';
export default {
  components: {
    // PostItem,
    Articles: () => import('@/components/home/Articles'),
  },
  data() {
    return {
      loading: false,
      posts: [],
      meta: null,
      limit: 2,
      type: this.$route.query.type,
    };
  },
  methods: {
    ...mapActions(['getPostsAction', 'resetPaginationAction']),
    // async getPosts(page = 1) {
    //   try {
    //     this.loading = true;
    //     const { data } = await ApiService.get(
    //       `items/posts_tags?fields=*,post_id.*,post_id.featured_image.id,post_id.featured_image.data.full_url&filter[post_id.status]=published&filter[tag_id]=2&meta=*&limit=${this.limit}&page=${page}`
    //     );

    //     console.log('data', data.data);
    //     this.posts = data.data;
    //     this.meta = data.meta;
    //     this.loading = false;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  mounted() {
    // this.getPosts();
    // console.log('tt', this.type);
    this.getPostsAction({ page: 1, type: this.type });
  },
  computed: {
    ...mapGetters(['postsGetter']),
  },
  unmounted() {
    this.resetPaginationAction();
  },
};
</script>

<style></style>
